<template>
  <div class="fit">
    <div
      class="fit q-px-md row"
      style="flex-direction: column; min-height: calc(100vh - 50px);"
    >
      <div
        v-if="!isOpenGroup"
        class="row"
        style="margin: 0 -4px;"
      >
        <div
          v-for="group in groups"
          :key="group.id"
          class="col-4 col-md-2 q-pa-xs"
        >
          <div v-if="!group.children">
            <menu-option
              :option="group"
              @click="handleClick(group.path)"
            />
          </div>

          <div v-else-if="group.children.length > 1">
            <menu-option
              :option="group"
              @click="setGroup(group)"
            />
          </div>

          <div v-else>
            <menu-option
              :option="group.children[0]"
              @click="handleClick(group.children[0].path)"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="row"
        style="margin: 0 -4px;"
      >
        <div
          v-for="route of group.children"
          :key="route.path"
          class="col-4 col-md-2 q-pa-xs text-center"
        >
          <menu-option
            :option="route"
            @click="handleClick(route.path)"
          />
        </div>
      </div>

      <q-space />

      <div class="row">
        <div class="col" />

        <div class="col">
          <q-btn
            color="light-blue-9"
            no-caps
            class="fit"
            @click="handleBack"
          >
            <div>
              <div>
                <q-icon name="home" />
              </div>

              <div style="font-size: 16px;">
                {{ $t('Terminal') }}
              </div>
            </div>
          </q-btn>
        </div>

        <div class="col" />
      </div>
    </div>
  </div>
</template>

<script>
// Configs
import { groups } from './../../routes'

// Components
import MenuOption from './MenuOption.vue'

export default {
  name: 'MenuOptions',
  components: {
    MenuOption
  },
  emits: ['submit', 'back'],
  data () {
    return {
      appOptions: this.$appOptions,
      groups,
      isOpenMenu: false,
      group: groups[0],
      isOpenGroup: false,
      activeGroupId: null,
      activeRoute: null,
      isFullscreen: false
    }
  },
  methods: {
    handleDarkMode () {
      this.$q.dark.set(!this.$q.dark.isActive)
      localStorage.setItem('darkMode', JSON.stringify(this.$q.dark.isActive))
    },
    handleFullscreen () {
      return Promise.resolve(this.isFullscreen)
        .then(isFullscreen => {
          return isFullscreen
            ? this.$q.fullscreen.exit()
            : this.$q.fullscreen.toggle()
        })
        .then(() => {
          this.isFullscreen = !this.isFullscreen
        })
    },
    handleBack () {
      if (this.isOpenGroup) {
        this.isOpenGroup = false
        return
      }

      this.$emit('back')
    },
    setGroup (group) {
      this.group = group
      this.isOpenGroup = true
    },
    handleClick (path) {
      this.isOpenGroup = false
      this.$router.push(path)
      this.$emit('submit')
    }
  }
}
</script>
