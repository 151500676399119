<template>
  <div>
    <div
      class="row items-center q-pa-xs q-mx-xs border-bottom"
      :class="$q.dark.isActive ? 'text-white' : 'text-grey-7'"
    >
      <div class="row items-center text-subtitle1 clickable text-weight-bold" @click="handleApp">
        <q-icon
          name="keyboard_backspace"
          size="22px"
        />

        {{ $t('To APP') }}
      </div>

      <q-space />

      <div class="row items-center text-subtitle1 clickable text-weight-bold" @click="handleSettings">
        <q-icon
          name="settings"
          size="22px"
          class="q-mr-xs"
        />

        <span>
          {{ userdata.email }}
        </span>
      </div>
    </div>

    <q-slide-transition>
      <div
        v-if="isOpen"
        class="row q-px-sm"
        style="border-bottom: 1px solid rgb(113, 113, 113)"
      >
        <div
          v-for="tile in tiles"
          :key="tile.id"
          class="col-4 col-md-2 q-pa-xs"
        >
          <div
            class="text-center rounded row items-center justify-center clickable"
            :class="tile.value ? 'bg-light-blue-9' : 'bg-grey-10'"
            style="min-height: 76px;"
            @click="tile.onChanged(!tile.value)"
          >
            <div class="q-px-sm">
              <q-icon :name="tile.icon" size="2rem" />

              <div class="text-caption">
                {{ tile.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'QuickSettings',
  data () {
    return {
      isOpen: false,
      appOptions: this.$appOptions,
      userdata: JSON.parse(localStorage.getItem('userData'))
    }
  },
  computed: {
    ...mapGetters([
      'terminalSettings'
    ]),
    tiles () {
      return [
        {
          id: 1,
          label: this.$t('Catch all'),
          icon: 'view_list',
          value: this.terminalSettings.catchAll,
          onChanged: (catchAll) => {
            this.updateTerminalSettings({ catchAll })
          }
        },
        {
          id: 2123,
          label: this.$t('Open camera'),
          icon: 'qr_code',
          value: false,
          onChanged: () => {
            this.handleOpenCamera()
          }
        },
        {
          id: 2,
          label: this.$t('Hide barcode'),
          icon: 'qr_code',
          value: this.terminalSettings.hideBarcodeField,
          onChanged: (hideBarcodeField) => {
            this.updateTerminalSettings({ hideBarcodeField })
          }
        },
        {
          id: 3,
          label: this.$t('Hide'),
          icon: 'keyboard',
          value: this.terminalSettings.hideKeyboard,
          onChanged: (hideKeyboard) => {
            this.updateTerminalSettings({ hideKeyboard })
          }
        },
        {
          id: 4,
          label: this.$t('Debug'),
          icon: 'adb',
          value: this.appOptions.debug,
          onChanged: (debug) => {
            this.updateAppOptions({ debug })
          }
        },
        {
          id: 5,
          label: this.$t('Dark Mode'),
          icon: 'dark_mode',
          value: this.$q.dark.isActive,
          onChanged: isActive => {
            this.$q.dark.set(isActive)
            localStorage.setItem('darkMode', JSON.stringify(isActive))
          }
        },
        {
          id: 6,
          label: this.$t('Full screen'),
          icon: this.$q.fullscreen && this.$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen',
          value: this.$q.fullscreen && this.$q.fullscreen.isActive,
          onChanged: isActive => {
            if (isActive) {
              return this.$q.fullscreen.request()
                .catch(err => {
                  this.addErrorNotification(err.message)
                })
            }

            return this.$q.fullscreen.exit()
          }
        },
        {
          id: 5124,
          label: this.$t('Logout'),
          icon: 'logout',
          value: false,
          onChanged: () => {
            this.$centrifuge.disconnect()
            this.$channels.user.unsubscribe()
            localStorage.clear()
            this.$eventBus.update('rules', { user: null, app: 'security' })
          }
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'updateTerminalSettings',
      'openCamera'
    ]),
    handleOpenCamera () {
      this.$eventBus.update('menu', { isOpen: false })
      this.openCamera()
    },
    handleApp () {
      this.$router.push('/')
        .then(() => {
          this.$eventBus.update('rules', { app: 'app' })
        })
    },
    handleSettings () {
      this.isOpen = !this.isOpen
    },
    updateAppOptions (update) {
      this.appOptions = { ...this.appOptions, ...update }
      localStorage.setItem('appOptions', JSON.stringify(this.appOptions))
      this.$eventBus.update('rules', { appOptions: this.appOptions })
    }
  }
}
</script>
