<template>
  <div :class="$q.dark.isActive ? 'text-white clickable' : 'text-dark clickable'">
    <div
      class="text-center menu-rounded row items-center justify-center relative"
      :class="'bg-' + option.color"
      style="height: 80px;"
      @click="handleClick"
    >
      <div
        class="menu-rounded"
        style="position: absolute; top: 3px; bottom: 3px; right: 3px; left: 3px;"
        :class="$q.dark.isActive ? 'bg-dark' : 'bg-white'"
      >
        <div
          class="row items-center justify-center fit"
          style="object-fit: contain;"
        >
          <img
            v-if="option.image"
            :src="option.image"
            :alt="option.title"
            class="fit"
            style="object-fit: contain;"
          >

          <q-icon
            v-else
            :name="option.icon"
            size="50px"
          />
        </div>
      </div>
    </div>

    <div
      class="text-caption text-weight-bold"
      :class="$q.dark.isActive ? 'text-white' : 'text-grey-9'"
    >
      {{ $t(option.title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOption',
  emits: ['click'],
  props: {
    option: {
      type: Object,
      default () {
        return {
          color: 'teal',
          title: 'Option',
          icon: 'settings'
        }
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', this.option)
    }
  }
}
</script>

<style scoped>
.menu-rounded {
  border-radius: 15px;
}
</style>
