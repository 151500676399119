<template>
  <div>
    <portal v-if="hasBackButton" to="tiles">
      <div
        class="text-center rounded row items-center justify-center"
        style="background: rgba(0, 0, 0, 0.9); min-height: 66px;"
        @click="handleBack"
      >
        <div class="q-px-sm">
          <q-icon name="arrow_back" size="2rem" />

          <div class="text-caption">
            {{ $t('Back') }}
          </div>
        </div>
      </div>
    </portal>

    <transition name="terminal-item">
      <portal-target
        v-show="isOpenSettings"
        class="q-px-md fixed"
        :style="`right: 0; left: 0; top: ${settingsDefaultPosition}px;;`"
        ref="settingsPortal"
        name="settings"
        @change="handleSettingsChange"
      />
    </transition>

    <div
      class="menu text-center text-white terminal-shadow"
      :class="$q.dark.isActive ? '' : 'bg-white'"
      :style="`transform: translateY(${top}px);`"
    >
      <div
        v-if="top !== topPosition"
        :class="$q.dark.isActive ? '' : 'text-dark'"
        class="q-my-xs row justify-between"
      >
        <div
          class="col q-px-xs"
          :style="`height: ${height - 7}px; padding-top: 2px;`"
        >
          <q-btn
            color="light-blue-9"
            class="fit"
            no-caps
            :disabled="!hasSettings"
            @click="openCloseSettings"
          >
            <div>
              <div>
                <q-icon name="settings" />
              </div>

              <div style="font-size: 16px;">
                {{ $t('Options') }}
              </div>
            </div>
          </q-btn>
        </div>

        <div
          class="col"
          :style="`height: ${height - 7}px; padding-top: 2px;`"
        >
          <q-btn
            color="light-blue-9"
            no-caps
            class="fit"
            @click="handleExpand"
          >
            <div>
              <div>
                <q-icon name="home" />
              </div>

              <div style="font-size: 16px;">
                {{ $t('Terminal') }}
              </div>
            </div>
          </q-btn>
        </div>

        <div
          class="col q-px-xs"
          :style="`height: ${height - 5}px; overflow: auto;`"
        >
          <portal-target
            ref="headerPortal"
            name="header"
            :style="`height: ${height - 5}px; overflow: auto;`"
          />
        </div>
      </div>

      <quick-settings />

      <q-scroll-area class="q-pt-sm" style="height: 100%; width: 100%;">
        <menu-options
          ref="menuOptions"
          @submit="handleExpandColl"
          @back="handleExpand"
        />
      </q-scroll-area>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import MenuOptions from './MenuOptions.vue'
import QuickSettings from './QuickSettings.vue'

// Utils
import { getMenuOptionByRoute } from '../../routes'

const height = 65

function getDefaultPosition () {
  return window.innerHeight - height
}

export default {
  name: 'Menu',
  components: {
    MenuOptions,
    QuickSettings
  },
  data () {
    return {
      height,
      settingsDefaultPosition: getDefaultPosition() - 100,
      top: 0,
      topPosition: 0,
      sub: null,
      settingsHeight: 0,
      isOpenSettings: false,
      hasSettings: false
    }
  },
  computed: {
    ...mapGetters([
      'terminalSettings'
    ]),
    hasBackButton () {
      return !getMenuOptionByRoute(this.$route.fullPath)
    },
    hasContent () {
      return !this.terminalSettings.hideBarcodeField
    }
  },
  created () {
    this.sub = this.$eventBus.subscribe('menu', ({ isOpen }) => {
      this.isOpenSettings = false

      if (!isOpen) {
        this.top = getDefaultPosition()
        return
      }

      this.top = this.topPosition
    })

    this.handleResize = this.handleResize.bind(this)
    window.addEventListener('resize', this.handleResize)
  },
  unmounted () {
    this.sub.unsubscribe()
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleBack () {
      this.$router.go(-1)
    },
    openCloseSettings () {
      this.isOpenSettings = !this.isOpenSettings

      if (this.isOpenSettings) {
        this.$nextTick(() => {
          const child = this.$refs.settingsPortal.children()[0]
          const defPosition = getDefaultPosition()

          let diff = child && child.el
            ? defPosition - child.el.clientHeight
            : defPosition

          if (defPosition === diff) {
            diff -= 150
          }

          this.settingsDefaultPosition = diff
        })
      }
    },
    handleSettingsChange (newContent) {
      this.hasSettings = newContent
      this.isOpenSettings = false
    },
    handleExpand () {
      this.isOpenSettings = false

      this.top = this.top === 0
        ? getDefaultPosition()
        : 0
    },
    handleResize () {
      this.top = getDefaultPosition()
    },
    handleExpandColl () {
      this.top = getDefaultPosition()
    }
  }
}
</script>

<style>
.bg-q-dark {
  background: #1d1d1d;
}

.terminal-shadow {
  box-shadow: rgb(0 0 0) 0 4px 16px;
}

.menu {
  background: #1d1d1d;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 1312;
  position: fixed;
  transition: transform .2s, background .3s;
}
</style>
